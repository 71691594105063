// assets/js/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../sass/cart.sass';


// Displaying content of data in layout div
function displayFlashbag(data) {
    $('#flashbag-display').remove();
    $(document).scrollTop(0);
    
    $('.main-container').first().prepend("<div id='flashbag-display'>"+data+"</div>");
}

// Displaying error message in layout div
function displayError(msg) {
    if (msg=='' || typeof msg == 'undefined') msg = 'Une erreur est survenue.';
    $(document).scrollTop(0);
    displayFlashbag("<div class='alert alert-danger' role='alert'>"+msg+"</div>");
}

function toggleCartSidebar() {
    $('.cart-sidebar-toggle').toggleClass('active');
    $('.overly-mask').toggleClass('is-visible');
    $('.cart-sidebar').toggleClass('is-visible');
    $('.cartMenu ').toggleClass('open');
    $('body').toggleClass('modal-open');
    if ($('.megamenu').hasClass('stuck')) {
        $('.cart-sidebar').addClass('hasTopBar');
    } else {
        $('.cart-sidebar').removeClass('hasTopBar');
    }
}

function closeSidebarCart() {
    $(".cart-close-trigger").on('click', function (event) {
        $('.overly-mask').toggleClass('is-visible');
        $('.cart-sidebar').toggleClass('is-visible');
        $('.cart-sidebar-toggle').toggleClass('active');
        $('.cartMenu ').toggleClass('open');
        $('body').toggleClass('modal-open');
        //$('.cart-sidebar').toggleClass('hasTopBar');
    });
}

function lightRefreshSideBar() {
    $.get(generateUrl($('#sidebar-content').data('refresh-url'))  + '?tk=' + makeid(), function(data) {
        $('.cart-sidebar-content').html(data);
        //$('.cart-sidebar-content select').select2();
        //activateScrollbar();
        deletePanier();
        closeSidebarCart();
    });
    updateCartArticlesCount();
}

function refreshSideBar() {
    $.get(generateUrl($('#sidebar-content').data('refresh-url'))  + '?tk=' + makeid(), function(data) {
        $('.cart-sidebar-content').html(data);
        $('.cart-xs-content').html(data);
        //$('.cart-sidebar-content select').select2();
        closeSidebarCart();
        deletePanier();
        //activateScrollbar();

        // Open sidebar if not already open
        if(!$('.cartMenu').hasClass('open'))
            toggleCartSidebar();

        // Hide sidebar after 3 seconds
        // setTimeout(function() {
        //     toggleCartSidebar();
        // }, 3000);
    });
    updateCartArticlesCount();
    updateMarquageQuantity();
}

function deletePanierArticle(element, id) {
    var route = element.data('delete-url');
    $.get(generateUrl(route, {id: id}), function(data) {
        if(data == "success") {
            refreshSideBar();
        }
    });
}

function deletePanier() {
    $('.cart-sidebar .deleteCart').on('click', function(e) {
        e.preventDefault();
        var that = $(this);
        
        bootbox.dialog({
            message: "Attention: vous allez supprimer ce panier.<br/>Voulez-vous continuer ?",
            title: "",
            buttons: {
              danger: {
                label: "ANNULER",
                className: "btn-danger",
                callback: function() {
                }
              },
              success: {
                label: "OUI",
                className: "btn-success",
                callback: function() {
                    $.ajax({
                        url: that.attr('href'),
                        type: 'GET',
                        success: function(data) {
                            location.reload();
                        },
                        error: function() {
                            displayError();
                        }
                    });
                }
              }
            }
        });
    });
}

window.closeSidebarCart = closeSidebarCart;
window.deletePanier = deletePanier;
//window.activateScrollbar = activateScrollbar;
window.toggleCartSidebar = toggleCartSidebar;

// Generate random id
function makeid()
{
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < 5; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

function updateCartArticlesCount() {
    $.get(generateUrl($('#sidebar-content').data('update-articles-count')), function(data) {
        $('#shoppingCartCount').html(data);
    });
}

/**
 * @param {string} routeName
 * @param {object} $params
 * @returns {string}
 */
function generateUrl(routeName, $params) {
    if ($params === undefined) {
        $params = {};
    }
    var $publicSlug = $('#sidebar-content').data('client-slug');
    if ($publicSlug !== undefined && $publicSlug !== '') {
        $params.client_slug = $publicSlug;
    }
    return Routing.generate(routeName, $params);
}

function updateMarquageQuantity() {
    var $params = {id : $('#add-article').attr('data-article')};
    $.get(generateUrl($('#sidebar-content').data('update-article-count'), $params), function(data) {
        $('#dmarquage input[name="quantite"]').val(data);
    });
}

function deliveryAddressModal(){
}
    
jQuery(function($) {
    // Quick adding of single article
    $('#add-article').on('click', function(e) {
        e.preventDefault();
        var color = $('#quick-add select[name=color]').val();
        var size = $('#quick-add select[name=size]').val();
        var qt = $('#quick-add input[name=qt]').val();
        var url = $(this).data('path');
        var qtmin = $('#client-prices-table th[data-min-quantity]').data('min-quantity');

        if(color != null && size != null && qt != '') {
            if(qt < qtmin) {
                displayError("La quantité minimum de commande pour cet article est de "+qtmin+" pièces. N'hésitez pas à nous contacter pour toute question.");
                
                return false;
            }
            $.ajax({
                url: url + "?zkpColor=" + color + "&zkpSize=" + size,
                type: 'POST',
                data: { qt: qt },
                success: function(data) {
                    displayFlashbag(data);
                    refreshSideBar();
                    if($("#quick_search").length > 0) {
                        $("#quick_search").val('');
                    }
                },
                error: function() {
                    displayError();
                }
            });
        } else {
            displayError("Vous devez choisir une couleur et une taille.");            
        }
    });
    
    // Multiple adding with table
    $('#table-quantities').on('submit', 'form[name=multiple_add_cart]', function(e) {
        e.preventDefault();
        
        $(this).ajaxSubmit(function(data) {
            $('#table-quantities .product-story-inner').html(data);
            refreshSideBar();
        });
    });
                
    
    // Delete product from shopping cart
    $('.cart-sidebar-content').on('click', '.miniCartProduct .delete', function() {
        var id = $(this).attr('data-id');
        var cart_id = $(this).attr('cart-id');
        $.get(Routing.generate('cybernecard_site_article_delete_from_cart', {id: id}), function() {
            lightRefreshSideBar();
        });
    });

    // Delete whole product from shopping cart
    $('.cart-sidebar-content').on('click', '.miniCartProduct .wholedelete', function() {
        $.get(generateUrl($(this).data('remove-link'), {id: $(this).data('article-id'), cart_id: $(this).data('card-id')}), function() {
            // Refreshing sidebar content
            lightRefreshSideBar();
        });
    });

    // Delete marquage from shopping cart
    $('.cart-sidebar-content').on('click', '.deleteMarquage', function() {
        var id = $(this).attr('data-id');
        
        $.get(generateUrl($(this).data('path'), {id: id}), function() {
            // Refreshing sidebar content
            lightRefreshSideBar()
        });
    });
    
    // Changing current shopping cart
    $('.cart-sidebar').on('change', 'select[name=shopping-carts]', function() {
        var value = $(this).val();

        $.get(generateUrl('cybernecard_site_shopping_cart_change_sidebar', {id: value}), function(data) {
            $('.cart-sidebar-content').html(data);
            //$('.cart-sidebar-content select').select2();
            //activateScrollbar();
            deletePanier();
            closeSidebarCart();
        });

        updateCartArticlesCount();

        //lightRefreshSideBar();


    });
    
    // Post form to create new shopping cart
    $('.cart-sidebar').on('submit', 'form[name=new_shopping_cart]', function(e) {
        e.preventDefault();
        $(this).ajaxSubmit(function(data) {
            $('.cart-sidebar-content').html(data);
            //$('.cart-sidebar-content select').select2();
            //activateScrollbar();
            deletePanier();
            closeSidebarCart();
            updateCartArticlesCount();
            updateMarquageQuantity();
        });
    });
    
    $('.cart-sidebar').on('click', '.delete-color-btn', function() {
        var id = $(this).data('pa-id');
        if($(this).data('alert-del') == 1) {
            bootbox.dialog({
                message: "Attention: le retrait de ces articles entrainera la suppression d'un ou plusieurs marquages associés.<br/>Voulez-vous continuer ?",
                title: "",
                buttons: {
                  danger: {
                    label: "ANNULER",
                    className: "btn-danger",
                    callback: function() {
                    }
                  },
                  success: {
                    label: "OUI",
                    className: "btn-success",
                    callback: function() {
                        deletePanierArticle($(this), id);
                    }
                  }
                }
            });
        } else {
            deletePanierArticle($(this), id);
        }
    });
    
    $('.cartContent .delete-color-btn').on('click', function(e) {
        e.preventDefault();
        var url = $(this).attr('href');
        
        if($(this).data('alert-del') == 1) {
            bootbox.dialog({
                message: "Attention: le retrait de ces articles entrainera la suppression d'un ou plusieurs marquages associés.<br/>Voulez-vous continuer ?",
                title: "",
                buttons: {
                  danger: {
                    label: "ANNULER",
                    className: "btn-danger",
                    callback: function() {
                    }
                  },
                  success: {
                    label: "OUI",
                    className: "btn-success",
                    callback: function() {
                        document.location.href = url;
                    }
                  }
                }
            });
        }
        else
            document.location.href = url;
    });
    
    $('form[name="update_cart"]').on('submit', function(e) {
        var form = this;
        e.preventDefault();
        
        var alert = false;
        // Looping on each articles
        $(".CartProduct").each(function() {
            var nArticles = 0;
            var qts = $(this).find("input[name^='qt-']");
            $.each(qts, function (index, qt) {
                nArticles += parseInt($(qt).val()); // Getting total number of articles
            });
            
            // If total number is inferior to max marquage
            if($(this).data('max-marquage') > nArticles)
                alert = true;
        });
        
        if(alert === true) {
            bootbox.dialog({
                message: "Attention: le retrait de ces articles entrainera la suppression d'un ou plusieurs marquages associés.<br/>Voulez-vous continuer ?",
                title: "",
                buttons: {
                  danger: {
                    label: "ANNULER",
                    className: "btn-danger",
                    callback: function() {
                    }
                  },
                  success: {
                    label: "OUI",
                    className: "btn-success",
                    callback: function() {
                        form.submit();
                    }
                  }
                }
            });
        }
        else
            form.submit();
    });

    $('.cart-sidebar .renameCart').on('click', function(e) {
        e.preventDefault();
        var that = $(this);

        var locale = {
            OK: 'Renommer',
            CONFIRM: 'Renommer',
            CANCEL: 'Annuler'
        };
        bootbox.addLocale('custom', locale);
        bootbox.prompt({
            title: "Renommer le panier",
            message: "Renommer le panier",
            value: that.attr('name'),
            locale: 'custom',
            callback: function(result) {
                $.ajax({
                    url: that.attr('href'),
                    type: 'POST',
                    data : 'id=' + that.attr('cid') + '&name=' + result,
                    success: function(data) {
                        location.reload();
                    },
                    error: function() {
                        displayError();
                    }
                });
            }
        });
    });

    
    $('#deliveryAddressModal, #infosModal, #paymentModeModal, .marquageFormModal').on('submit', 'form', function(e) {
        e.preventDefault();
        var form = $(this);

        $(this).ajaxSubmit(function(data) {
            // If success, reloading page
            if(data === 'success')
                location.reload();
            else {// else displaying content (form errors) in modal
                form.parents('.modal-content').html(data);
                //$('.modals select').select2();
            }
        });

        return false;
    });

    $('#deliveryAddressModal').on('change', 'select[name="address_select"]', function(e) {
        var value = $(this).val();
        if(value != "") {
            $('#deliveryAddressModal #deliveryAddress'+value+' span').each(function(index) {
                if ($(this).attr('data-name') == 'country') {
                    console.log('changed address. '+$(this).attr('data-name')+' : '+$(this).text());
                    $('#form_country').val($(this).text());
                    $('#form_country').trigger('change');
                } else {
                    $('#deliveryAddressModal input[name="form['+$(this).attr('data-name')+']"]').val($(this).text())
                }
            });
        }
    });

    $('#form_rgpd').on('click', function() {
        $('#form_cgv').prop('checked', true);
    });

    
    $('#deliveryAddressModal').on('shown.bs.modal', function () {
        $("#divdelivselect select").select2({
            dropdownParent: $("#deliveryAddressModal"),
            theme: 'required'
        });
    });

});